import React from "react"
import Layout from "../components/layout"
import { useStaticQuery, graphql } from "gatsby"
import SEO from "../components/seo"
import EventTag from "../components/event-tag"
import EventFields from "../components/event-fields"
import Img from "gatsby-image"
import "./events.scss"

const EventsPage = () => {
  const queryResult = useStaticQuery(graphql`
    query EventPageQuery {
      events: allMarkdownRemark(
        sort: { fields: frontmatter___startDate, order: DESC }
        filter: { fields: { sourceInstanceName: { eq: "events" } } }
      ) {
        edges {
          node {
            id
            frontmatter {
              type
              title
              location
              startDate
              endDate
              images
            }
          }
        }
      }
      photos: allFile(filter: { sourceInstanceName: { eq: "photos" } }) {
        nodes {
          childImageSharp {
            fixed(width: 360, height: 270) {
              ...GatsbyImageSharpFixed
              originalName
            }
          }
          id
        }
      }
    }
  `)
  const events = queryResult.events.edges.map((x) => ({ ...x.node.frontmatter, id: x.node.id }))
  const photos = new Map(
    queryResult.photos.nodes.map((x) => [x.childImageSharp.fixed.originalName, x])
  )
  for (const event of events) {
    event.images = event.images
      .map((fileName) => {
        const image = photos.get(fileName)
        return image === undefined ? null : { key: image.id, fixed: image.childImageSharp.fixed }
      })
      .filter((x) => x !== null)
  }
  return (
    <Layout>
      <SEO title="Events" />
      {events.map((event) => (
        <div className="event-card" id={event.id} key={event.id}>
          <h2 className="title">
            <EventTag type={event.type}>{event.type}</EventTag>
            {event.title}
          </h2>
          <EventFields event={event} />
          <div className="gallery">
            {event.images.map((image) => (
              <Img className="image" key={image.key} fixed={image.fixed} alt={event.title} />
            ))}
          </div>
        </div>
      ))}
    </Layout>
  )
}

export default EventsPage
